
.cart_detail_wrapper
{
    width: 100%;
    height: auto;
}

.cart_detail_parent_wrapper
{
    width: 100%;
    height: auto;
}

.cart_detail_content_section
{
    width: 100%;
    height: auto;
}

.cart_table_heading_section th
{
    padding: 0.8rem 0.8rem!important;
    border-bottom: 2px solid #ebebeb!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #999999;
}

.cart_table_products
{
    width: 50%;
    min-width: 250px;
    padding: 0.8rem 0.8rem!important;
}

.cart_table_price
{
    width: 20%;
}

.cart_table_quantity
{
    width: 20%;
}

.cart_table_cart_btn
{
    width: 10%;
}

.cart_table_product_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
}

.cart_table_product_img_section
{
    width: 30%;
    height: 100%;
}

.cart_table_product_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
}

.cart_table_product_title_section
{
    width: 68%;
    height: 100%;
    display: flex;
    align-items: center;
}

.cart_table_product_title_section h2
{
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.cart_table_product_price_section
{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
}

.cart_table_product_price_section h3
{
    font-size: 18px;
    color: #2ea2cc;
    font-weight: 600;
    margin: 0;
}

.cart_table_product_quantity_section
{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
}

.cart_table_product_subtotal_section
{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
}

.cart_table_product_subtotal_section h4
{
    font-size: 18px;
    color: #2ea2cc;
    font-weight: 600;
    margin: 0;
}

.cart_table_cross_icon_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_cross_icon
{
    font-size: 25px;
    color: #333;
}

.table_cross_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.coupon_code_section
{
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
}

.apply_coupon-btn
{
    border: 0;
    padding: 6px 20px;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    background-color: #2ea2cc;
    border-radius: 5px;
}

.basket_total_section
{
    width: 100%;
    height: auto;
}

.basket_total_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.basket_total_content_section
{
    width: 100%;
    height: auto;
}

.basket_total_content_area
{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.basket_total_content_area h3
{
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.basket_total_content_area span
{
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.basket_total_content_area span:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.basket_total_content_area .shipping_icon
{
    font-size: 25px;
}

.basket_total_content_area p
{
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #2ea2cc;
    margin: 0;
}

.cart_detail_checkout_btn
{
    border: 1px solid #2ea2cc;
    background-color: #2ea2cc;
    color: white;
    padding: 8px 15px;
    border-radius: 4px;
    text-decoration: none;
}

.cart_detail_checkout_btn:hover
{
    color: white;
}