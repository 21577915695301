.cart_section
{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;

}

.cart_layer_section
{
    width: 60%;
    height: 100vh;
    background-color: rgba(0,0,0,.55);
    transform: translateX(-100%);
    transition: all 1s ease-in-out;
}

.cart_layer_trans
{
    transform: translateX(0%);
}

.cart_area
{
    width: 40%;
    height: 100vh;
    background-color: #fff;
    transform: translateX(100%);
    transition: all 1s ease-in-out;
}

.cart_area_trans
{
    transform: translateX(0%);
    /* transition: all 4s ease-in-out; */
}

.cart_top_section
{
    width: 100%;
    height: auto;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.1);
}

.cart_top_left_section
{
    width: 100%;
    height: 50px;
    display: flex;
    gap: 20px;
    align-items: center;

}

.cart_icon
{
    font-size: 44px;
    color: #2ea2cc;
}

.cart_top_left_section span
{
    font-size: 24px;
    color: #333;
    font-weight: 600;
}

.cart_top_right_section
{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.cross_icon
{
    font-size: 20px;
    color: #333;
    margin-right: 20px;
}

.cross_icon:hover
{
    cursor: pointer;
    color: #2ea2cc;
}

.cart_content_area
{
    width: 100%;
    height: auto;
    user-select: none;
}

.cart_card_bottom_section
{
    width: 100%;
    height: auto;
    border-top: 2px solid #2ea2cc;
}

.sub_total_section
{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.sub_total_section h5
{
    font-size: 17px;
    color: #333;
    font-weight: 500;
    margin: 0;
}

.sub_total_section p
{
    font-size: 17px;
    color: #2ea2cc;
    font-weight: bold;
    margin: 0;
}

.cart_card_bottom_btn_section
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.cart_card_bottom_btn_section a
{
    border: 1px solid #333;
    background-color: transparent;
    color: #333;
    padding: 5px 15px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    text-decoration: none;
}

.cart_card_bottom_btn_section a:hover
{
    color: #fff;
    background-color: #2ea2cc;
    border: 1px solid #2ea2cc;
}