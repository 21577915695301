.pagination_wrapper
{
    display: flex;
    justify-content: end;
}

.prev_btn
{
    background-color: transparent;
    color: #000;
    font-weight: bold;
    border-radius: 0.25rem;
    margin: 0.25rem 0.25rem;
    border: none;
}

.prev_btn:hover
{
    color: #2ea2cc;
}

.pageNumber
{
    background-color: #6b7280;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin: 0.25rem 0.5rem;
    border: none;
}

.pageNumber
{
    background-color: #4b5563;
}

.currentPage
{
    background-color: #2ea2cc;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    margin: 0.25rem 0.5rem;
    border: none;
    width: 35px;
    height: 35px;
}


.otherPages
{
    color: #000;
    font-weight: bold;
    border-radius: 50%;
    margin: 0.25rem 0.25rem;
    border: none;
    width: 35px;
    height: 35px;
    background-color: transparent;
}

.otherPages:hover
{
    background-color: #2ea2cc;
    color: white;
}

.nextBtn
{
    background-color: transparent;
    color: #000;
    font-weight: bold;
    border-radius: 0.25rem;
    margin: 0.25rem 0.25rem;
    border: none;
}

.nextBtn:hover
{
    color: #2ea2cc;
}

.dots
{
    margin: 0 10px;
}