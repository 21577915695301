
.dashboard-container {
  display: flex;
  height: 100vh; 
}

.menu-section {
  flex: 0 0 250px;
  background-color: #f8f9fa;
  padding: 20px;
}

.content-section {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.dashboard-container .menu-section .nav-item 
{
    cursor: pointer;
}

.dashboard-container .menu-section .nav-item a
{
    font-size: 16px;
    color: #212529;
    font-weight: 500;
}

.dashboard-container .menu-section .nav-item .active
{
    width: 100%!important;
    height: auto!important;
    color: #2ea2cc;
    background-color: transparent;
    font-weight: 600;
}

.logo-section
{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.logo-section img
{
    width: 150px;
    object-fit: contain;
    /* display: block;
    margin: 0 auto; */
}

.content-area
{
    width: 100%;
    height: auto;
}

.content-area h1
{
    font-size: 40px;
    color: #333;
    font-weight: 500;
    text-align: center;
}

.order-listing {
    padding: 20px;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: #fff;
    border-collapse: collapse;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table th {
    text-align: left;
    padding-top: 0.8rem!important;
    padding-bottom: 0.8rem!important;
    border-bottom: 2px solid #ebebeb!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #999999;
  }

    
  .table tr {
    padding-top: 0.8rem!important;
    padding-bottom: 0.8rem!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .details-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
  }

  .cancel-button
  {
    background-color: #d11a2a;
    color: #fff;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 5px;
  }
  
  .details-button:hover {
    background-color: #0056b3;
  }

  .status {
    font-size: 12px;
    padding: 5px 13px;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .shipped {
    background-color: #28a745;
    color: #fff;
  }
  
  .cancel {
    background-color: #f80a0a;
    color: #fff;
  }
  
  .NOT_PROCESSED {
    background-color: #007bff;
    color: #fff;
  }

  .user-profle-section
  {
    width: 100%;
    height: auto;
  }
  .user-profile-area
  {
    width: 100%;
    height: auto;
  }

  .update-btn-section
  {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }

  .productImg
  {
    width: 120px;
    object-fit: contain;
  }

  .order-detail-content-area
  {
    max-width: 100%;
    width: fit-content;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }