
.checkout_wrapper
{
    width: 100%;
    height: auto;
}

.checkout_parent_wrapper
{
    width: 100%;
    height: auto;
}

.checkout_content_section
{
    width: 100%;
    height: auto;
}

.checkout_returning_customer_section
{
    width: 100%;
    height: auto;
}

.returning_customer_area
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.returning_customer_content_area
{
    max-width: 310px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, .0) 0px 0px 0px, rgba(0, 0, 0, .20) 0px 1px 4px 2px, rgba(0, 0, 0, .14) 0px -3px 0px inset;
}

.returning_customer_content_area img
{
    width: 60px;
    display: block;
    margin: 0 auto;
}

.returning_customer_content_area p
{
    text-align: center;
    color: #333;
    margin: 10px 0 5px 0;
    font-size: 17px;
    font-weight: 600;
}

.returning_customer_content_area a
{
    text-align: center;
    display: block;
    text-decoration: none;
    color: #333;
}

.returning_customer_content_area a:hover
{
    color: #2ea2cc;
}

.returning_customer_content_area span
{
    text-align: center;
    display: block;
    text-decoration: none;
    color: #333;
}

.returning_customer_content_area span:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.returning_customer_content_area .coupon_code_section
{
    gap: 10px;
    margin-top: 10px;
}

.returning_customer_content_area .apply_coupon-btn
{
    width: 65%;
    padding: 0px 10px;
    font-size: 12px;
}

.billing_detail_section
{
    width: 100%;
    height: auto;
}

.billing_detail_left_section
{
    width: 100%;
    height: auto;
}

.billing_detail_left_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.billing_detail_right_section
{
    width: 100%;
    height: auto;
}

.billing_detail_right_section h3
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.your_order_detail_section
{
    width: 100%;
    height: auto;
}

.your_order_detail_section h4
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.checkout_table_heading_section th {
    padding: 0.8rem 0.8rem!important;
    border-bottom: 2px solid #ebebeb!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #999999;
}

.your_order_detail_section .table tr td
{
    padding: 0.8rem 0.8rem;
}

.your_order_detail_section .table tr td span
{
    font-size: 15px;
    color: #333;
    margin-right: 5px;
}

.your_order_detail_section .table tr td p
{
    font-weight: 600;
    font-size: 15px;
    color: #333;
}

.your_order_detail_section .table tr td .checkout_total_price
{
    color: #2ea2cc;
}

.checkout_payment_section
{
    width: 100%;
    height: auto;
    background: #ebe9eb;
    padding: 0.8rem 0.8rem;
}

.checkout_payment_paypal_section
{
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
    align-items: center;
}

.checkout_payment_paypal_section h3
{
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin: 0;
}

.checkout_payment_payment_section
{
    width: fit-content;
    height: 50px;
}

.checkout_payment_payment_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
}

.checkout_payment_paypal_section a
{
    font-size: 15px;
    color: #333;
    font-weight: 600;
    margin: 0;
    text-decoration: none;
}

.checkout_payment_paypal_section a:hover
{
    color: #2ea2cc;
}

.checkout_payment_creditcard_section
{
    width: 100%;
    height: auto;
    background-color: #dfdcde;
    position: relative;
    padding: 12px 20px;
    border-radius: 5px;
    border-radius: 3px;
    margin: 14px 0;
}

.checkout_payment_creditcard_section::after
{
    content: "";
    position: absolute;
    top: -25px;
    left: 13px;
    display: block;
    border: 0.8em solid #dfdcde;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
}

.checkout_payment_creditcard_section p
{
    font-size: 15px;
    color: #333;
    margin: 0;
}

.checkout_payment_section p
{
    font-size: 15px;
    color: #333;
    margin: 0;   
}

.checkout_payment_btn_section
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.payment_btn
{
    border: 0;
    background-color: #2ea2cc;
    color: white;
    padding: 6px 14px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
}

.payment_btn a
{
    color: white;
    text-decoration: none;
}

.payment_btn a:hover
{
    color: white;
}

.Or-text
{
    font-size: 18px;
    font-weight: 700;
    margin: 0 15px;
}