
.resetpassword_wrapper
{
    width: 100%;
    height: auto;
}

.resetpassword_parent_section
{
    width: 100%;
    height: auto;
}

.resetpassword_content_section
{
    width: 100%;
    height: auto;
}

.resetpassword_content_area
{
        max-width: 700px;
        height: auto;
        background-color: #ebe9eb;
        margin: 0 auto;
        padding: 20px 40px;
        border-radius: 5px;
}

.resetpassword_content_area p
{
    font-size: 15px;
    color: #333;
    margin: 10px 0;
}

.resetpassword_content_area .resetPassword-btn
{
    border: 0;
    background-color: #4ea4cc;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 15px 0;
    margin-right: 20px;
}