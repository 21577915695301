#modal-modal-title
{
    text-align: center;
}

#modal-modal-description
{
    text-align: center;
}

#modal-modal-image img
{
    width: 50px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}
