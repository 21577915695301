
.return_policy_wrapper
{
    width: 100%;
    height: auto;
}

.return_policy_parent_section
{
    width: 100%;
    height: auto;
}

.return_policy_content_section
{
    width: 100%;
    height: auto;
}

.return_policy_content_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.return_policy_content_section ul li
{
    font-size: 15px;
    color: #909295;
    line-height: 27px;
}

.leave_your_comment_section
{
    width: 60%;
    height: auto;
}

.leave_your_comment_section h3
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}


.contact_us_form_section
{
    width: 100%;
    height: auto;
}

.form_input_field
{
    width: 100%;
    height: auto;
    margin-top: 20px!important;
}

.submit_btn
{
    margin: 15px 0;
    border: 0;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-transform: uppercase;
    padding: 10px 40px;
    background: #333;
}