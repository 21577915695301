
.order_tracking_wrapper
{
    width: 100%;
    height: auto;
}

.order_tracking_parent_section
{
    width: 100%;
    height: auto;
}

.order_tracking_content_section
{
    width: 100%;
    height: auto;
}

.order_tracking_desc_section
{
    width: 100%;
    height: auto;
}

.order_tracking_desc_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.order_tracking_desc_section p
{
    font-size: 15px;
    color: #909295;
    line-height: 32px;
    margin: 0;
}

.order_tracking_desc_section ul li a
{
    text-decoration: none;
    color: #333;
    font-size: 15px;
    font-weight: bold;
}

.order_tracking_desc_section ul li a:hover
{
    color: #2ea2cc;
}

.order_tracking_img_section
{
    width: 100%;
    height: auto;
}

.order_tracking_img_area
{
    width: 100%;
    height: 220px;
}

.order_tracking_img_area img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}