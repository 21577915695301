
.product_card_section
{
    width: 100%;
    height: auto;
    margin-top: 20px;
    border: 1px solid rgba(0,0,0,.18);
    padding: 10px;
    border-radius: 5px;
    position: relative;
}

.product_card_img_section
{
    width: 100%;
    height: 120px;
}

.product_card_img_section img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product_card_content_section
{
    width: 100%;
    height: auto;
}

.product_card_content_section .CardHoverTitle
{
    font-size: 1em;
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_card_content_section .CardHoverTitle:hover
 {
    color: #2ea2cc;
    cursor: pointer;
 }

.product_card_content_section p
{
    font-size: 1.1em;
    color: #2ea2cc;
    font-weight: 600;
}

.product_rating_section
{
    width: 100%;
    height: auto;
}

.product_rating_icon
{
    font-size: 1.2rem;
    color: #ffb503;
}

.cards_hover_section
{
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    background-color: rgba(0, 0, 0, .35);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.shopping_cart_btn
{
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping_cart_btn:hover
{
    background-color: #2ea2cc;
    cursor: pointer;
}

.shopping_cart_btn:hover .cards_hover_icon
{
    color: white;
}


.favourite_btn
{
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.favourite_btn:hover
{
    background-color: #2ea2cc;
    cursor: pointer;
}

.favourite_btn:hover .cards_hover_icon
{
    color: white;
}

.sm_loader_hover
{
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sm_loader_hover:hover
{
    background-color: #fff;
}
