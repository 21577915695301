.thanku_wrapper
{
    width: 100%;
    height: auto;
}

.thanku_parent_section
{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
}

.thanku_content_area
{
    max-width: 900px;
    height: auto;
    width: 100%;
    margin: 0 auto;
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 5 px;
}

.thanku_content_area img
{
    width: 120px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
}

.thanku_content_area h1
{
    font-size: 40px;
    color: #333;
    text-align: center;
    margin: 20px 0;
}

.thanku_content_area p
{
    font-size: 20px;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
}

.continue-shopping-btn
{
    border: 0;
    background-color: #2ea2cc;
    color: white;
    padding: 6px 14px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    display: block;
    margin: 20px auto;
}

.continue-shopping-btn a
{
    text-decoration: none;
    color: white;
}