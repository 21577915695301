
.favourtie_wrapper
{
    width: 100%;
    height: auto;
}

.favourite_parent_section
{
    width: 100%;
    height: auto;
}

.favourite_content_section
{
    width: 100%;
    height: auto;
    margin: 40px 0;
}

.table_product_heading_section th
{
    padding: 0.8rem 0.8rem!important;
    border-bottom: 2px solid #ebebeb!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #999999;
}

.card_title
{
    font-size: 1rem;
    color: black;
    font-weight: 600;
    margin-bottom: 5px;
}

.card_title:hover
{
   color: #2ea2cc;
   cursor: pointer;
}

.table_products
{
    width: 50%;
    padding: 0.8rem 0.8rem!important;
}

.table_price
{
    width: 15%;
    padding: 0.8rem 0.8rem!important;
}

.table_stock
{
    width: 15%;
    padding: 0.8rem 0.8rem!important;
}

.table_cart_btn
{
    width: 20%;
    padding: 0.8rem 0.8rem!important;
}


.table_product_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: space-between;
}

.table_product_img_section
{
    width: 30%;
    height: 100%;
}

.table_product_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
}

.table_product_title_section
{
    width: 68%;
    height: 100%;
    display: flex;
    align-items: center;
}

.table_product_title_section h2
{
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.table_product_price_section
{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
}

.table_product_price_section h3
{
    font-size: 18px;
    color: #2ea2cc;
    font-weight: 600;
}

.table_product_stock_section
{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
}

.table_product_stock_section h4
{
    font-size: 18px;
    color: #a6c76c;
    font-weight: 600;
}

.table_cart_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_cross_icon_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_cross_icon
{
    font-size: 25px;
    color: #333;
}

.table_cross_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.MuiPaper-root
{
    padding: 10px 5px!important;
}

.empty_list_section
{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty_list_text
{ 
    font-size: 34px;
    color: #333;
    font-weight: 600;
}
