
.footer_wrapper
{
    width: 100%;
    height: 330px;
    background-image: url('../Images/bg_footer.jpg');
    margin-top: 50px;
    background-position: bottom;
    position: relative;
}

.footer_wrapper::after
{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.85);
}

.footer_section
{
    width: 100%;
    height: 100%;
    padding: 30px 0 5px 0;
    position: relative;
    z-index: 100;
}

.inner_sec_container
{
    max-width: 1160px;
    height: auto;
    margin: 0 auto;
}

.about_store_section
{
    width: 100%;
    height: auto;
}

.footer_heading
{
    font-size: 1.5em;
    color: white;
    font-weight: bold;
}

.footer_content_section
{
    width: 100%;
    height: auto;
    margin-top: 25px;
}

.footer_content_section p
{
    font-size: 0.9em;
    color: #DDDDDD;
}

.footer_content_section p a
{
    font-size: 1em;
    color: #DDDDDD;
    text-decoration: none;
}

.footer_content_section p a:hover
{
    color: #2ea2cc;
}

.footer_content_icon
{
    font-size: 22px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.useful_links
{
    width: 100%;
    height: auto;
}

.social_links
{
    width: 100%;
    height: auto;
}

.copyright_text
{
    text-align: center;
    color: #DDDDDD;
    font-size: 1em;
    margin: 0;
}

hr
{
    color: white!important;
}