
.contact_us_wrapper
{
    width: 100%;
    height: auto;
}

.breadcrum_section
{
    width: 100%;
    height: auto;
}
.contact_us_parent_section
{
    width: 100%;
    height: auto;
}

.contact_us_map_section
{
    width: 100%;
    height: 400px;
}

.contact_us_desc_section
{
    width: 100%;
    height: auto;
}

.contact_us_desc_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.contact_us_desc_section p
{
    font-size: 14px;
    line-height: 26px;
    max-width: 80%;
    color: #909295;
    text-align: center;
    margin: 0 auto;
}

.contact_us_info_section
{
    width: 100%;
    height: auto;
}

.contact_us_info_area
{
    width: 100%;
    height: auto;
    padding-top: 20px;
}

.contact_us_info_area h3
{
    color: #333;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 14px;
    text-transform: uppercase;
}

.contact_us_info_area p
{
    font-size: 15px;
    line-height: 30px;
    color: #909295;
}

.contact_us_info_area .contact_us_icon
{
    font-size: 20px;
    margin-right: 15px;
}

.loc
{
    color: #EA4335;
}

.mail
{
    color: #dd4e41;
}

.phone
{
    color: #2091eb;
}

.contact_us_form_section
{
    width: 100%;
    height: auto;
}

.form_input_field
{
    width: 100%;
    height: auto;
    margin-top: 20px!important;
}

.submit_btn
{
    margin: 15px 0;
    border: 0;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-transform: uppercase;
    padding: 10px 40px;
    background: #333;
}