
.search_wrapper
{
    width: 100%;
    height: auto;
}

.search_parent_section
{
    width: 100%;
    height: auto;
}

.search_content_section
{
    width: 100%;
    height: auto;
}

.search_content_area
{
    width: 100%;
    height: auto;
    padding: 20px;
    border: 1px solid #949494;
    border-radius: 5px;
}

.search_product_img_section
{
    width: 100%;
    height: 140px;
}

.search_product_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.search_product_content_area
{
    width: 100%;
    height: 100%;
}

.search_content_section h2
{
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.search_product_content_area h3
{
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 5px;
}

.search_product_content_area span
{
    font-size: 14px;
    color: #949494;
    margin-right: 20px;
}

.search_product_content_area .search_product_icon
{
    font-size: 16px;
    color: #333;
    margin-bottom: 4px;
    margin-right: 4px;
}

.search_product_content_area p
{
    font-size: 15px;
    color: #949494;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
}