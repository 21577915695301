.product_detail_wrapper
{
    width: 100%;
    height: auto;
}

.product_detail_content_section
{
    width: 100%;
    height: auto;
    margin-top: 25px;
}

.product_detail_left_content_area
{
    width: 100%;
    height: auto;
}

.product_detail_right_content_area
{
    width: 100%;
    height: auto;
    padding: 10px 0;
}

.product_detail_img_section
{
    width: 100%;
    height: auto;
    padding: 10px 0px;
}

.product_detail_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product_detail_img_section .carousel .thumbs-wrapper
{
    display: block;
}

 .carousel .control-dots .dot
{
    background: black;
}

.carousel .carousel-slider .control-arrow
{
    top: calc(50% - 20px);
    color: white;
    font-size: 26px;
    margin-top: 0px;
    background: #2ea2cc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.carousel .carousel-slider .control-arrow
{
    top: calc(50% - 20px);
    color: white;
    font-size: 26px;
    margin-top: 0px;
    background: #2ea2cc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.product_detail_img_section .carousel .thumb
{
    height: 80px;
}

.p_detail_img_area
{
    width: 100%;
    height: 450px;
    border: 1px solid rgba(0, 0, 0, 0.175);;
    border-radius: 3px;
}

.p_detail_img_area div
{
    width: 100%!important;
    height: 100%!important;
}

.p_detail_img_area div .product_img
{
    width: 100%!important;
    height: 100%!important;
    object-fit: contain!important;
}

.p_detail_img_area div div
{
    height: auto!important;
    margin: 0 auto;
}

.p_detail_img_area div div div
{
    margin: 0 auto;
    height: auto!important;
    width: fit-content!important;
}

.product_sm_img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/* .p_detail_img_area img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

.product_title
{
    font-size: 22px;
    color: #333;
    font-weight: 500;
    width: 100%;
    height: auto;
}

.rating_section
{
    width: 100%;
    height: auto;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.product_rating_icon
{
    font-size: 1em;
    color: #ffb503;
}

.customer_review
{
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
}

.product_price_section
{
    width: 100%;
    height: auto;
    padding: 5px 0;
}

.product_price
{
    font-size: 22px;
    font-weight: 500;
    color: #2ea2cc;
}

.discount_price
{
    font-size: 14px;
    font-weight: 500;
    color: #2ea2cc;
    margin-left: 10px;
}

.product_available_stock
{
    font-size: 14px;
    color: #a6c76c;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: bold;
}

.product_out_of_stock
{
    font-size: 14px;
    color: rgb(219, 9, 9);
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: bold;
}

.product_description
{
    width: 100%;
    height: auto;
    padding: 10px 0;
}

.product_description h2
{
    font-size: 17px;
    color: #333;
}

.product_description ul li
{
    font-size: 15px;
    color: #949494;
}

.share_product
{
     width: 100%;
     height: auto;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 10px 0;
}

.share_product h2
{
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.social_links_section
{
    width: 45%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social_links_area
{
    width: 40px;
    height: 40px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fb
{
    background-color: #1e4297;
}

.twitter
{
    background-color: #1c96e8;
}

.linkedIn
{
    background-color: #0073b1;
}

.googlePlus
{
    background-color: #d54c3f;
}

.pinterest
{
    background-color: #b7081b;
}

.social_links_area:hover
{
    filter: brightness(1.5);
    cursor: pointer;
}

.product_cart_section
{
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 20px 0;
}

.counter_section
{
    width: auto;
    height: auto;
    padding: 5px;
}

.counter
{
    padding: 0 7px;
    font-size: 18px;
    font-weight: bold;
}

.counter_minus
{
    font-size: 18px;
    font-weight: bold;
    display: inline;
    border: none;
    background: #ff00008f;
    color: white;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.counter_plus
{
    font-size: 18px;
    font-weight: bold;
    display: inline;
    border: none;
    background: #0080008a;
    color: white;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.counter_icon
{
    margin-bottom: 4px;
}

.counter_quantity_heading
{
    margin-right: 10px;
    font-weight: 500;
    font-size: 18px;
    color: #333;
}

.product_cart_add_section
{
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #333;
    color: white;
}

.product_cart_add_section:hover
{
    background-color: #2ea2cc;
    color: white;
}

.product_cart_fav_section
{
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    background-color: #333;
    color: white;
}

.product_cart_fav_section:hover
{
    background-color: #2ea2cc;
    color: white;
}

.fav_icon
{
    margin-bottom: 3px;
}

.product_reviews_section
{
    width: 100%;
    height: auto;
}

.product_reviews_section h2
{
    font-size: 17px;
    color: #333;
}

.product_reviews_content_section
{
    width: 100%;
    height: 100%;
    max-height: 320px;
    overflow: auto;

}

.product_reviews_area
{
    width: 100%;
    height: auto;
    margin: 15px 0;
    background-color: whitesmoke;
    padding: 20px 15px;
    border-radius: 5px;
}

.user_name
{
    margin: 0;
    color: grey;
    font-size: 13px;
    padding-left: 5px;
}

.user_comment
{
    font-size: 18px;
    margin-top: 10px;
    color: #333;
    padding-left: 5px;
}
